/* Landing Page */
.landing-page {
  background: linear-gradient(to right, #00f, #00a);
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jumbotron {
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 10px;
}